import 'bootstrap';
const feather = require('feather-icons');
window.feather = feather;

if ($('body').hasClass('route_user_create')) {
  $(function() {
    $('#user_email').on('paste', function(event) {
      // Get the clipboard data
      let pastedText = (event.originalEvent.clipboardData || window.clipboardData).getData('text');
      // Match paste of "Roman Kreichman <roman.kreichman@kaltura.com>"
      let regex = /(\w+)\s(\w+)\s<(.+)>/i;
      let matchResult = pastedText.match(regex);
      if (!matchResult) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      $('#user_firstName').val(matchResult[1]);
      $('#user_lastName').val(matchResult[2]);
      $('#user_email').val(matchResult[3]);
    });
  })
}
